import { Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import {  bemooreColors } from "../../styles/theme"

const useStyles = makeStyles(theme => ({
  cardsHeader: {
    textAlign: "center",
    padding: "3rem",
    backgroundColor: "inherit",

    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0rem",
    },
  },
  cardsHeaderText: {
    fontWeight: 700,
    color: bemooreColors.secondary,
  },
  cardsSubHeader: {
    padding: "1rem",
    margin: "0 auto",
    maxWidth: "50%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

const SectionHeader = ({ title, subtitle }) => {
  const classes = useStyles()
  return (
    <Paper elevation={0} className={classes.cardsHeader}>
      <Typography className={classes.cardsHeaderText} variant="h4">
        {title}
      </Typography>
      <div className={classes.cardsSubHeader}>
        <Typography variant="body1">{subtitle}</Typography>
      </div>
    </Paper>
  )
}

export default SectionHeader
