import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import React from "react"
import Desk from "../images/desk.svg"
import { bemooreColors, BemooreCTAButton } from "../styles/theme"
import GridSection from "./common/GridSection"
import CIRCLES from "../images/circles.png"
const useStyles = makeStyles(theme => ({
  headerContent: {
    lineHeight: "3rem",
    fontWeight: 700,
    padding: "1rem 0",
    color: bemooreColors.secondary,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "2rem",
    },

    "& > span:nth-child(1)": {
      color: bemooreColors.primary,
    },
  },
}))

const Hero = ({ intl }) => {
  const classes = useStyles()

  const renderLeft = () => (
    <div>
      <Typography
        gutterBottom
        className={classes.headerContent}
        component="h1"
        variant="h3"
      >
        <span>Bemoore creates </span>
        tailored websites and Web solutions Focused On Your Success!
      </Typography>

      <BemooreCTAButton href="#contact">Find out More! </BemooreCTAButton>
    </div>
  )

  return (
    <GridSection
      lazy={false}
      left={renderLeft()}
      img={Desk}
      minHeight="70vh"
      bgImg={CIRCLES}
      section={"HERO"}
      dimensions={{height:"337.91px",width:'608px'}}
    />
  )
}
export default Hero
