import { Grid, Paper, Typography, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import BemooreSection from "../components/common/BemooreSection"
import SectionHeader from "../components/common/SectionHeader"
import APP from "../images/app-development.svg"
import WEB from "../images/computer.svg"
import STATS from "../images/stats.svg"
import {  bemooreColors, BemooreCTAButton } from "../styles/theme"

const useStyles = makeStyles(theme => ({
  cardsOuter: {
    minHeight: "80vh",
    padding: "3rem",
    backgroundColor: bemooreColors.bgBlue,
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },

  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2.6rem 1rem",
    boxShadow: "0px 1px 14px -2px rgba(50, 50, 74, 0.69)",
    position: "relative",
  },
  svg: {
    width: "4rem",
    padding: ".5rem 2rem",
  },
  cardContentWrapper: {
    textAlign: "center",
    maxWidth: "17rem",
    padding: "0rem 1rem 1rem",
  },
  cardHead: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: bemooreColors.secondary,
    padding: ".2rem 0 1rem",
  },
  cta: {
    position: "absolute",
    minWidth: "50%",
    bottom: 0,
    right: 0,
  },
}))

const Cards = () => {
  const classes = useStyles()
  return (
    <BemooreSection>
      <Grid container spacing={3} className={classes.cardsOuter}>
        <Grid item xs={12}>
          <SectionHeader
            title={"Discover our expertise"}
            subtitle={
              "We focus on serving small and medium-sized companies the necessary support and tools to enhance their digital presence and compete with big companies."
            }
          />
        </Grid>
        <Container fixed>
          <Grid container spacing={8} alignItems="center" justify="center">
            <Grid item>
              <Paper square className={classes.card}>
                <img
                  loading="lazy"
                  className={classes.svg}
                  src={WEB}
                  alt="Computer illustration"
                />
                <div className={classes.cardContentWrapper}>
                  <Typography variant="body1" className={classes.cardHead}>
                    Web Design
                  </Typography>
                  <Typography varianr="body2" gutterBottom>
                    Enhance your web visibility by opting for a modern and
                    accessible web solution.
                  </Typography>
                </div>
                <BemooreCTAButton href="#contact" className={classes.cta}>
                  Build My Website
                </BemooreCTAButton>
              </Paper>
            </Grid>
            <Grid item>
              <Paper square className={classes.card}>
                <img
                  loading="lazy"

                  className={classes.svg}
                  src={APP}
                  alt="Application illustration"
                />
                <div className={classes.cardContentWrapper}>
                  <Typography variant="body1" className={classes.cardHead}>
                    Mobile Development
                  </Typography>
                  <Typography varianr="body2" gutterBottom>
                    Go beyond the web and maintain your reach by offering your
                    customers a mobile experience.
                  </Typography>
                  <BemooreCTAButton href="#contact" className={classes.cta}>
                    Build My app
                  </BemooreCTAButton>
                </div>
              </Paper>
            </Grid>
            <Grid item>
              <Paper square className={classes.card}>
                <img
                  loading="lazy"

                  className={classes.svg}
                  src={STATS}
                  alt="Statistics illustration"
                />
                <div className={classes.cardContentWrapper}>
                  <Typography variant="body1" className={classes.cardHead}>
                    SEO Optimization
                  </Typography>
                  <Typography varianr="body2" gutterBottom>
                    Get your website to the top of Google search results, and
                    see your customer count soar.
                  </Typography>
                  <BemooreCTAButton href="#contact" className={classes.cta}>
                    OPtimize my Website
                  </BemooreCTAButton>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </BemooreSection>
  )
}

export default Cards
