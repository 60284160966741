import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { bemooreColors } from "../styles/theme"

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  listText: {
    color: bemooreColors.primary,
    fontWeight: "bold",
  },
})

const SideDrawer = ({ state, toggleDrawer }) => {
  const classes = useStyles()

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={() => toggleDrawer("right", false)}
      onKeyDown={() => toggleDrawer("right", false)}
    >
      <List>
        {["HOME", "WORK", "BLOG", "CONTACT"].map((text, index) => (
          <ListItem button key={index}>
            <ListItemText className={classes.listText}>{text}</ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  )
}

export default SideDrawer
