import { Container, ThemeProvider } from "@material-ui/core"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Cards from "../components/Cards"
import Header from "../components/Header"
import Hero from "../components/Hero"
import { graphql } from "gatsby"
import theme from "../gatsby-theme-material-ui-top-layout/theme"
import SEO from "../components/SEO"
import Details from "../components/Details"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import "../styles/index.css"

const Home = props => {
  const intl = useIntl()
  return (
    
    <ThemeProvider theme={theme}>
      <Container maxWidth={false}>
        <SEO title={`Website `} pathname={props.location.pathname} />
        <Header router={props} />
        <Hero intl={intl} />
        <Cards intl={intl} />
        <Details intl={intl} data={props.data.site.siteMetadata.cardsData} />
        <Contact intl={intl} />
        <Footer intl={intl} />
      </Container>
    </ThemeProvider>
  )
}

export const query = graphql`
  query dataQuery {
    site {
      siteMetadata {
        cardsData {
          title
          subTitle
          btnText
          img
          direction
        }
      }
    }
  }
`
export default Home
