import React from "react"
import { makeStyles } from "@material-ui/styles"
import {
  Container,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core"
import  Facebook from "@material-ui/icons/Facebook"
import { bemooreColors } from "../styles/theme"

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor:bemooreColors.bgGrey,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  copyContainer :{
    display: "flex",
    justifyContent:'flex-end',
    marginRight:'1rem',
  },
  copyright: {
    fontWeight:700,
    color: bemooreColors.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".7rem",
    },
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: bemooreColors.primary,
    "&:hover": {
      backgroundColor: "#A9F1D1",
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: "0 1rem",
    },
  },
  iconSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}))
const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.container}>
      <Container maxWidth="lg">
        <Grid
          direction="row"
          container
          alignItems="center"
          justify="center"
        >
          <Grid className={classes.copyContainer} item >
            <Typography className={classes.copyright}>
            Bemoore © {new Date().getFullYear()}
            </Typography>
          </Grid>
          <Grid item >
          <a name='facebook' target="_blank" rel="noreferrer" href="https://www.facebook.com/Bemoore.be">
                <Avatar className={classes.small}>
                  <Facebook className={classes.iconSize} />
                </Avatar>
              </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
