import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { bemooreColors, BemooreCTAButton } from "../styles/theme"
import GridSection from "./common/GridSection"
import APP from "../images/app.svg"
import UI from "../images/ui.svg"
import WORKING from "../images/working.svg"
const useStyles = makeStyles(theme => ({
  headerContent: {
    fontWeight: 700,
    color: bemooreColors.secondary,
    padding: ".9rem 0",
    [theme.breakpoints.down("sm")]: {
      padding: ".3rem 0",
    },
  },
  subHeading: {
    padding: "0.5rem 0",
  },
}))

const images = {
  app: APP,
  ui: UI,
  working: WORKING,
}

const resolvImage = name => {
  return images[name]
}

const Detail = ({ title, subTitle, btnText, img, direction }) => {
  const classes = useStyles()
  const renderLeft = () => (
    <div>
      <Typography
        gutterBottom
        className={classes.headerContent}
        component={"h2"}
        variant={"h4"}
      >
        {title}
      </Typography>
      <Typography gutterBottom className={classes.subHeading} variant={"body1"}>
        {subTitle}
      </Typography>
      <BemooreCTAButton href="#contact">{btnText}</BemooreCTAButton>
    </div>
  )

  return (
    <GridSection
      lazy={true}
      left={renderLeft()}
      img={resolvImage(img)}
      direction={direction}
      padding={"2.5rem 0"}
      section={"DETAIL"}
    />
  )
}

export default Detail
