import { AppBar, Button } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

export const bemooreColors = {
  primary: "#4db882",
  secondary: "#4e4e4e",
  tertiary: "#fbf9f9",
  bgGrey: "#FBF9F9",
  bgBlue: "#F7FDFD",
}

export const addBorder = (width, color) => ({
  border: `${width} solid ${color}`,
})

export const BemooreAppbar = withStyles({})(AppBar)

export const BemooreHeaderButton = withStyles({
  root: {
    boxSizing: "border-box",
    maxWidth: "90%",
    borderRadius: 0,
    color: bemooreColors.primary,
    fontSize: "1rem",
    fontWeight: "bold",
    border: `1px solid transparent`,

    "&:active": {
      backgroundColor: "inherit",
      border: `1px solid transparent`,
    },
    "&:hover": {
      backgroundColor: "inherit",
      borderBottom: `1px solid ${bemooreColors.primary}`,
    },
  },
})(Button)

export const BemooreCTAButton = withStyles({
  root: {
    backgroundColor: bemooreColors.primary,
    borderRadius: 0,
    padding: ".5rem 1rem",
    fontWeight: 700,
    color: bemooreColors.secondary,
  },
})(Button)
