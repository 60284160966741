import React from "react"
import Detail from "./Detail"
import SectionHeader from "../components/common/SectionHeader"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import BemooreSection from "../components/common/BemooreSection"

const useStyles = makeStyles(theme => ({
  headingGrid: {
    padding: props => props.padding,
    border: "2px solid",
  },
  detailsOuter: {
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
}))

const Details = props => {
  const classes = useStyles(props)
  const renderDetails = siteData => {
    return siteData?.map((detailElement, index) => {
      const { title, subTitle, btnText, img, direction } = detailElement
      return (
        <Detail
          key={index}
          title={title}
          subTitle={subTitle}
          btnText={btnText}
          img={img}
          direction={direction}
        />
      )
    })
  }

  return (
    <BemooreSection>
      <Grid
        justify="center"
        container
        className={classes.detailsOuter}
        spacing={2}
      >
        <Grid item xs={12}>
          <SectionHeader
            title={"How do we work"}
            subtitle={
              "Use our expertise to design and plan your business growth strategy."
            }
          />
        </Grid>
        {renderDetails(props.data)}
      </Grid>
    </BemooreSection>
  )
}

export default Details
