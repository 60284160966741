import { Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Toolbar, IconButton } from "@material-ui/core"
import Menu from "@material-ui/icons/Menu"
import SideDrawer from "./SideDrawer"
import React, { useState } from "react"
import Logo from "../images/logo.png"
import {
  BemooreAppbar,
  BemooreHeaderButton,
  bemooreColors,
} from "../styles/theme"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  logo: {
    flexGrow: 3,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: bemooreColors.primary,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  menu: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))

export default function Header({ router }) {
  const classes = useStyles()
  const [state, setState] = useState({
    right: false,
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  return (
    <nav className={classes.root}>
      <BemooreAppbar
        elevation={0}
        className={classes.bar}
        color="transparent"
        position="static"
      >
        <Toolbar>
          <div className={classes.logo}>
            <Avatar alt="Bemoore logo" src={Logo} />
          </div>
          <div className={classes.menu}>
            <BemooreHeaderButton size="large">HOME</BemooreHeaderButton>
            <BemooreHeaderButton href="#contact" size="large">
              WORK
            </BemooreHeaderButton>
            <BemooreHeaderButton
              rel="noopener noreferrer"
              target="_blank"
              href="https://blog.bemoore.be"
              size="large"
            >
              BLOG
            </BemooreHeaderButton>
            <BemooreHeaderButton href="#contact" size="large">
              CONTACT
            </BemooreHeaderButton>
          </div>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={toggleDrawer("right", true)}
          >
            <Menu/>
          </IconButton>
        </Toolbar>
      </BemooreAppbar>
      <SideDrawer state={state} toggleDrawer={toggleDrawer} />
    </nav>
  )
}
