import React, { useState } from "react"
import GridSection from "./common/GridSection"
import {
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  InputLabel,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {  bemooreColors, BemooreCTAButton } from "../styles/theme"
import PC from "../images/ctBg.jpg"
const useStyles = makeStyles(() => ({
  headerContent: {
    fontWeight: 700,
    color: bemooreColors.secondary,
  },
  subHeading: {
    padding: "0.5rem 0",
  },
  formBtn: {
    width: "100%",
  },
  right: {},
}))

const Contact = () => {
  const [service, setService] = useState("")
  const handleChange = event => {
    setService(event.target.value)
  }
  const classes = useStyles()

  const renderRight = () => (
    <div id="contact" className={classes.left}>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Full name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="phone"
              variant="outlined"
              required
              fullWidth
              id="phone"
              label="Phone"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="service">I am interested in</InputLabel>
              <Select
                id="demo-simple-select-outlined"
                labelId="service"
                value={service}
                onChange={handleChange}
                label="I am interested in"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"web"}>Getting a website</MenuItem>
                <MenuItem value={"app"}>Building my App</MenuItem>
                <MenuItem value={"seo"}>
                  Increase my website visibility
                </MenuItem>
                <MenuItem value={"info"}>
                  Request general information
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="message"
              variant="outlined"
              required
              fullWidth
              id="message"
              label="Message"
              multiline
              rows={4}
              rowsMax={10}
            />
          </Grid>
          <Grid item xs={12}>
            <BemooreCTAButton type="submit" className={classes.formBtn}>
              REQUEST
            </BemooreCTAButton>
          </Grid>
        </Grid>
      </form>
    </div>
  )
  const renderLeft = () => (
    <div className={classes.right}>
      <Typography
        gutterBottom
        className={classes.headerContent}
        component="h2"
        variant={"h4"}
      >
        Fill the contact form and receive a quote within the day!
      </Typography>
      <Typography gutterBottom className={classes.subHeading} variant="body1">
        Bemoore commits to helping you give your business the best digital coat.
      </Typography>
    </div>
  )
  return (
    <GridSection
      lazy={true}
      left={renderLeft()}
      padding={"4rem 0"}
      direction={"row-reverse"}
      right={renderRight()}
      img={false}
      bgImg={PC}
    />
  )
}

export default Contact
