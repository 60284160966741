import { Grid, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import BemooreSection from "./BemooreSection"
import {  bemooreColors } from "../../styles/theme"

const useStyles = makeStyles(theme => ({
  outer: {
    minHeight: props => props.minHeight,
    flexDirection: props => props.direction,
    padding: props => props.padding,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      flexDirection: props =>
        ["DETAIL", "HERO"].includes(props.section)
          ? "column-reverse"
          : props.direction,
      alignItems: "center",
    },
  },
  textGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  headerWrapper: {
    padding: "1rem",
  },
  section: {
    padding: props => props.padding,
    backgroundImage: props => `url(${props.bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0rem",
    },
  },
  headerContent: {
    lineHeight: "3rem",
    fontWeight: 700,
    padding: "1rem 0",
    color: bemooreColors.secondary,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "2rem",
    },

    "& > span:nth-child(1)": {
      color: bemooreColors.primary,
    },
  },

  imgWrapper: {
    textAlign: "center",
  },
  img: {
    maxWidth: "100%",
  },
}))

const GridSection = props => {
 
  const classes = useStyles(props)
  return (
    <BemooreSection hasCustomStyle customClass={classes.section}>
      <Container fixed>
        <Grid className={classes.outer} container spacing={2}>
          <Grid
            className={classes.textGrid}
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
          >
            <Grid container justify="center">
              <Grid item xs={10} sm={10} md={10} lg={10}>
                {props.left}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container={true}
            direction="row"
            alignItems="center"
            
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            {props.img && (
              <img
                loading={props.lazy? 'lazy':''}
                alt="desktop illustration"
                src={props.img}
                className={classes.img}
                height={props.dimensions && props.dimensions.height}
                width={props.dimensions && props.dimensions.width}
              />
            )}
            {props.right}
          </Grid>
        </Grid>
      </Container>
    </BemooreSection>
  )
}

export default GridSection
